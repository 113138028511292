.d-flex {
  display: flex !important;
}

.d-md-flex {
  display: flex !important;
}

a {
  text-decoration: none;
}

/* COMMENTATO PER VISUALIZZARE OPTIONS GRADING
/* #wrapper {
  overflow-x: hidden;
} */

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
  visibility: hidden;
}

#sidebar-wrapper .sidebar-heading {
  min-height: 48px;
  font-size: 1rem;
  width: 14rem;
  text-overflow: ellipsis;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
  visibility: visible;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
      margin-left: 0;
      visibility: visible;
  }

  #page-content-wrapper {
      min-width: 0;
      width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
    visibility: hidden;
  }
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}