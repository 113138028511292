
.it-header-center-wrapper {
    background: #06c;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px
}

.it-header-center-wrapper.it-small-header {
    height: 64px
}

.it-header-center-wrapper .it-header-center-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper .it-brand-text {
    padding-right: 24px
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a {
    display: flex;
    color: #fff;
    align-items: center;
    text-decoration: none
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .h2,
.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .it-brand-title,
.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
    font-size: 1.25em;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: unset;
    line-height: 1.1
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .h3,
.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .it-brand-tagline,
.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h3 {
    font-size: .889em;
    font-weight: 400;
    margin-bottom: 0
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a:hover {
    text-decoration: none
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
    fill: #fff;
    width: 48px;
    height: 48px;
    margin-right: 8px;
    flex-shrink: 0
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
    color: #fff;
    display: flex;
    align-items: center
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials {
    align-items: center;
    font-size: .889em
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon {
    fill: #fff;
    width: 24px;
    height: 24px;
    margin-left: 16px
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
    display: flex;
    align-items: center;
    font-size: .889em
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
    width: 24px;
    height: 24px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
    fill: #fff;
    width: 24px;
    height: 24px
}

@media(min-width:768px) {
    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
        margin-left: 40px
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
        margin-left: 8px
    }
}

@media(min-width:992px) {
    .it-header-center-wrapper {
        height: 120px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 6px
    }

    .it-header-center-wrapper.it-small-header {
        height: 104px
    }

    .it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a .h2,
    .it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h2 {
        font-size: 1.333em
    }

    .it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a .h3,
    .it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h3 {
        font-size: .778em;
        margin-top: 4px
    }

    .it-header-center-wrapper .it-header-center-content-wrapper {
        padding-left: 18px;
        padding-right: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 100%
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper {
        margin-left: 0
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a {
        display: flex;
        align-items: center
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .h2,
    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .it-brand-title,
    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
        font-size: 1.778em;
        margin-bottom: 0;
        font-weight: 600
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .h3,
    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .it-brand-tagline,
    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h3 {
        font-size: .889em;
        font-weight: 400;
        margin-bottom: 0
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a:hover {
        text-decoration: none
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
        width: 82px;
        height: 82px;
        margin-right: 16px
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
        display: flex;
        align-items: center
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials {
        align-items: center;
        font-size: .889em
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon {
        width: 24px;
        height: 24px;
        margin-left: 16px
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul a:hover svg {
        fill: #f2f2f2
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
        display: flex;
        align-items: center;
        margin-left: 80px;
        font-size: .889em
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        transition: all .3s
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon:hover {
        background: #f2f2f2
    }

    .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
        fill: #06c;
        width: 24px;
        height: 24px
    }
}

.it-header-wrapper .it-brand-wrapper .icon image {
    width: 48px;
    height: 48px;
}

@media (min-width: 992px) {
    .it-header-wrapper .it-brand-wrapper .icon image {
        width: 82px;
        height: 82px;
    }
}