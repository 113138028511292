/* make the Bootstrap customizations of variables and functions */

/* Include any default variable overrides here */

$theme-colors: (
  "primary": #0066cc,
  "secondary": #003183,
  "success":#28a745,
  "danger": #dc3545,
  "grey": #6c757d,
  "dark": #343a40,
  "warning": #ffc107,
  "info":#17a2b8,
  "AI": #ba55d3
);

$theme-color-interval: 10;

a {
  color: #0059b3;
  text-decoration: none;

  &:hover {
    color: #003183;
    text-decoration: underline;
  }
}

/* Include any default functions overrides here */

@function color($key: "blue") {
  @return map-get($colors, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

// @function theme-color-level($color-name: "primary", $level: 0) {
//   $color: theme-color($color-name);
//   $color-base: if($level > 0, #000, #fff);
//   $level: abs($level);

//   @return mix($color-base, $color, $level * $theme-color-interval);
// }

@function gray($key: "100") {
  @return map-get($grays, $key);
}

/* import the necessary Bootstrap files */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";




