.back-to-top {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background: #0066cc;
    width: 40px;
    height: 40px;
    text-decoration: none;
    border-radius: 50%;
    display: block;
    visibility: hidden;
    opacity: 0;
    text-align: center;
    -webkit-transition: .2s ease-in-out, visibility .3s linear, opacity .3s ease-in-out, -webkit-transform .25s cubic-bezier(.75, -.5, 0, 1.75);
    transition: .2s ease-in-out, transform .25s cubic-bezier(.75, -.5, 0, 1.75), visibility .3s linear, opacity .3s ease-in-out, -webkit-transform .25s cubic-bezier(.75, -.5, 0, 1.75);
    z-index: 1;
    -webkit-transform: scale(.7);
    transform: scale(.7);
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.back-to-top.back-to-top-show {
    visibility: visible;
    opacity: 1;
    -webkit-transition: .2s ease-in-out, visibility linear, opacity .1s ease-in-out, -webkit-transform 50ms ease-out;
    transition: .2s ease-in-out, transform 50ms ease-out, visibility linear, opacity .1s ease-in-out, -webkit-transform 50ms ease-out;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.back-to-top:hover {
    background: #004d99;
    text-decoration: none
}

.back-to-top .icon {
    margin: 0;
    position: relative;
    -webkit-transform: scale(.75);
    transform: scale(.75);
    top: 4px
}

.back-to-top .icon:before {
    margin: 0;
    color: #fff
}

.back-to-top.shadow:hover {
    -webkit-box-shadow: 0 .3rem .75rem rgba(0, 0, 0, .3) !important;
    box-shadow: 0 .3rem .75rem rgba(0, 0, 0, .3) !important
}

.back-to-top.dark {
    background: #fff
}

.back-to-top.dark:hover {
    background: #ebeced
}

.back-to-top.dark .icon:before {
    color: #435a70
}

@media (min-width:768px) {
    .back-to-top {
        width: 56px;
        height: 56px
    }

    .back-to-top .icon {
        -webkit-transform: scale(1);
        transform: scale(1);
        top: 10px
    }

    .back-to-top.back-to-top-small {
        width: 40px;
        height: 40px
    }

    .back-to-top.back-to-top-small .icon {
        -webkit-transform: scale(.75);
        transform: scale(.75);
        top: 4px
    }

    .link-list-wrapper ul li a.large {
        line-height: 2.7em
    }
}

@media (min-width:1200px) {
    .back-to-top {
        bottom: 32px;
        right: 32px
    }
}