.it-header-center-wrapper {
	background: #06c;
	height: 80px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 18px;
	padding-right: 18px
}

.it-header-center-wrapper.it-small-header {
	height: 64px
}

.it-header-center-wrapper .it-header-center-content-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper .it-brand-text {
	padding-right: 24px
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #fff;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
	font-size: 1.25em;
	margin-bottom: 0;
	font-weight: 600;
	letter-spacing: unset;
	line-height: 1.1
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h3 {
	font-size: .889em;
	font-weight: 400;
	margin-bottom: 0
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a:hover {
	text-decoration: none
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
	fill: #fff;
	width: 10em;
    height: 80%;
	margin-right: 8px;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .889em
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon {
	fill: #fff;
	width: 24px;
	height: 24px;
	margin-left: 16px
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .889em
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
	width: 24px;
	height: 24px;
	display: block;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
	fill: #fff;
	width: 24px;
	height: 24px
}

@media (min-width:768px) {
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
		margin-left: 40px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
		margin-left: 8px
	}
}

.it-header-center-wrapper.theme-light {
	background: #fff
}

.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-brand-wrapper a {
	color: #06c
}

.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-brand-wrapper a .icon {
	fill: #06c
}

.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-right-zone {
	color: #06c
}

.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon,
.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
	fill: #06c
}

.it-header-navbar-wrapper nav {
	background: 0 0
}

@media (max-width:992px) {
	.it-header-navbar-wrapper.theme-light-desk .custom-navbar-toggler .icon {
		fill: #06c
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .menu-wrapper {
		background: #06c
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description p {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li a.nav-link {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-left-color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .close-div .close-menu {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu h3 {
		font-weight: 600
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li a h3,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li a i,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li a span,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li h3,
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-dark-mobile .navbar .dropdown-menu .link-list-wrapper ul li .divider {
		background: #7ab8f5
	}
}

@media (min-width:992px) {
	.it-header-center-wrapper {
		height: 120px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 6px
	}
	.it-header-center-wrapper.it-small-header {
		height: 104px
	}
	.it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h2 {
		font-size: 1.333em
	}
	.it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h3 {
		font-size: .778em;
		margin-top: 4px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper {
		padding-left: 18px;
		padding-right: 18px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper {
		margin-left: 0
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
		font-size: 1.778em;
		margin-bottom: 0;
		font-weight: 600
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h3 {
		font-size: .889em;
		font-weight: 400;
		margin-bottom: 0
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a:hover {
		text-decoration: none
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
		width: 10em;
    	height: 80%;
		margin-right: 16px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: .889em
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon {
		width: 24px;
		height: 24px;
		margin-left: 16px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul a:hover svg {
		fill: #f2f2f2
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 80px;
		font-size: .889em
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		display: block;
		background: #fff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 16px;
		-webkit-transition: .3s;
		transition: .3s
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #f2f2f2
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #06c;
		width: 24px;
		height: 24px
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-right-zone .it-socials ul a:hover svg {
		fill: #0059b3
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a {
		background: #06c
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #fff
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #0059b3
	}
	.it-header-navbar-wrapper {
		background: #06c
	}
	.it-header-navbar-wrapper .nav-item.megamenu>a.dropdown-toggle:before {
		bottom: -14px
	}
	.it-header-navbar-wrapper nav a {
		text-decoration: none
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper {
		background: 0 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav {
		padding: 0
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav.navbar-secondary {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav.navbar-secondary a {
		font-size: .85em;
		line-height: 1.6
	}
	.it-header-navbar-wrapper.theme-light-desk {
		background: #fff;
		-webkit-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
		box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05)
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
		fill: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar {
		background: 0 0
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .menu-wrapper {
		background: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu a.nav-link:before {
		background-color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-description p {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link {
		color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-bottom-color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu {
		background: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu h3 {
		font-weight: 600
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu:before {
		background: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a h3,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a i,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a span,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li h3 {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li .divider {
		background: #d9dadb
	}
}

.it-header-wrapper .it-nav-wrapper {
	position: relative
}

.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
	padding-left: 32px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
	-webkit-transition: padding-top .3s;
	transition: padding-top .3s;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -22px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
	padding-left: 18px;
	padding-right: 18px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav .custom-navbar-toggler {
	padding: 0;
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
	cursor: pointer
}

.it-header-wrapper.it-header-sticky.is-sticky .it-nav-wrapper {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10
}

.it-header-wrapper.it-header-sticky.is-sticky~* .owl-carousel {
	z-index: auto
}

@media (min-width:992px) {
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-center-wrapper,
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-slim-wrapper {
		display: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper {
		position: fixed;
		width: 100%;
		top: 0
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper {
		-ms-flex-item-align: center;
		align-self: center
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned .it-brand-text {
		display: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a {
		color: #fff
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a:hover {
		text-decoration: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a .icon {
		fill: #fff;
		width: 81px;
		height: 41px;
		margin-right: 16px;
		-ms-flex-negative: 0;
		flex-shrink: 0
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-item-align: center;
		align-self: center;
		margin-left: 26.66667px;
		font-size: .889em;
		color: #fff
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		display: block;
		background: #fff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 16px;
		-webkit-transition: .3s;
		transition: .3s
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #f2f2f2
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #06c;
		width: 24px;
		height: 24px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper.cloned a {
		width: 35px;
		height: 35px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper.cloned a svg {
		width: 20px;
		height: 20px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .navbar-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .navbar-nav.navbar-secondary {
		display: none
	}
	.it-header-wrapper .it-nav-wrapper {
		position: relative
	}
	.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
		padding-left: 0
	}
	.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
		position: inherit;
		left: auto;
		top: inherit;
		margin-top: 0
	}
	.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
		padding-left: 18px;
		padding-right: 18px
	}
	.it-header-wrapper .navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
		left: 0;
		right: 0
	}
}

.it-header-slim-wrapper {
	background: #0059b3;
	padding: 6.5px 18px
}

.it-header-slim-wrapper .it-header-slim-wrapper-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .dropdown-menu {
	top: -9px!important
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a {
	color: #fff;
	text-decoration: none
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a:hover:not(.active) {
	text-decoration: underline
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a .icon {
	width: 18px;
	height: 18px;
	-webkit-transition: .3s;
	transition: .3s;
	-webkit-transform-origin: center;
	transform-origin: center;
	fill: #fff
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle {
	text-transform: uppercase;
	font-size: .778em;
	text-decoration: underline;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a.it-opener {
	font-size: .778em;
	padding: .5rem 0;
	display: block;
	text-decoration: none
}

.it-header-slim-wrapper .it-header-slim-wrapper-content a.it-opener[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .navbar-brand {
	color: #fff;
	font-size: .778em;
	padding: 0
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone,
.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: start;
	align-self: flex-start
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone .it-access-top-wrapper>a,
.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone button,
.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone .it-access-top-wrapper>a,
.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone button {
	background: #004080;
	padding-top: 7.5px;
	padding-bottom: 7.5px
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone .it-access-top-wrapper>a:hover,
.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone button:hover,
.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone .it-access-top-wrapper>a:hover,
.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone button:hover {
	background: #004d99
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list {
	margin-top: 16px;
	margin-bottom: 24px
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list a.list-item.active {
	color: #fff;
	cursor: default;
	text-decoration: none
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list a.list-item:hover:not(.active) {
	text-decoration: underline
}

.it-header-slim-wrapper.theme-light {
	background: #fff;
	border-bottom: 1px solid rgba(0, 89, 179, .2)
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .dropdown-menu:before,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .dropdown-menu:before {
	background: #0059b3
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .dropdown-menu,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .dropdown-menu {
	background-color: #0059b3
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .dropdown-menu a,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .dropdown-menu a span,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .dropdown-menu a,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .dropdown-menu a span {
	color: #fff
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .btn,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .btn {
	background: #06c;
	color: #fff
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .btn:hover,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .btn:hover {
	background: #06c
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content a {
	color: #0059b3
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content a .icon,
.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content a.it-opener[aria-expanded=true] .icon {
	fill: #0059b3
}

.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .navbar-brand {
	color: #0059b3
}

.it-header-slim-wrapper.theme-light .btn-full.btn-danger,
.it-header-slim-wrapper.theme-light .btn-full.btn-info,
.it-header-slim-wrapper.theme-light .btn-full.btn-primary,
.it-header-slim-wrapper.theme-light .btn-full.btn-secondary,
.it-header-slim-wrapper.theme-light .btn-full.btn-success,
.it-header-slim-wrapper.theme-light .btn-full.btn-warning {
	color: #fff!important
}

@media (min-width:992px) {
	.it-header-slim-wrapper {
		height: 48px;
		padding: 0
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .dropdown-menu {
		top: -14px!important
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile {
		-webkit-box-flex: 2;
		-ms-flex-positive: 2;
		flex-grow: 2
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list {
		margin: 0 16px 0 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 0 24px;
		border-left: 1px solid rgba(255, 255, 255, .2);
		border-right: 1px solid rgba(255, 255, 255, .2);
		height: 48px
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list a {
		padding-top: 5px;
		padding-bottom: 5px
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .nav-mobile ul.link-list a.active {
		border-bottom: 2px solid #fff;
		text-decoration: none
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle {
		padding-top: 12px;
		padding-bottom: 12px;
		text-decoration: none
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content a.dropdown-toggle .icon {
		margin-left: 4px
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content a.navbar-brand {
		padding-top: 12px;
		padding-bottom: 12px
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content .header-slim-right-zone,
	.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone {
		height: 48px
	}
	.it-header-slim-wrapper .it-header-slim-wrapper-content {
		padding: 0 18px;
		height: 48px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
	.it-header-slim-wrapper .collapse:not(.show) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .nav-mobile ul.link-list {
		border-left: 1px solid rgba(0, 89, 179, .2);
		border-right: 1px solid rgba(0, 89, 179, .2)
	}
	.it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .nav-mobile ul.link-list a.list-item.active {
		color: #06c;
		border-bottom: 2px solid #0059b3
	}
}

@media (min-width:992px) {
	.row.row-column-border {
		margin-top: 1rem
	}
	.row.row-column-border>[class^=col-] {
		padding: 3rem;
		border-top: none;
		border-left: 1px solid #b1b1b3
	}
	.row.row-column-border>[class^=col-]:first-child {
		border: none;
		padding-left: 0
	}
	.row.row-column-border>[class^=col-] .navbar .menu-wrapper,
	.row.row-column-menu-left>[class^=col-]:first-child,
	.row.row-column-menu-right>[class^=col-]:last-child {
		padding: 0
	}
}


.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
	padding: 24px 0 0;
	overflow: hidden
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
	padding-bottom: 0
}

.navbar .navbar-collapsable .navbar-nav {
	padding: 24px 0
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link {
	color: #06c;
	font-weight: 600;
	padding: 13px 24px;
	position: relative
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
	fill: #06c;
	-webkit-transition: .3s;
	transition: .3s
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
	border-left: 3px solid #06c
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link:hover:not(.active) {
	text-decoration: underline
}

.navbar .navbar-collapsable.expanded .menu-wrapper {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: .3s cubic-bezier(.29, .85, .5, .99);
	transition: .3s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable.expanded .close-div {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.navbar .close-div {
	/* -webkit-transform: translateX(-100%);
	transform: translateX(-100%); */
	position: fixed;
	left: auto;
	right: 2px;
	top: 24px;
	background: rgba(0, 0, 0, 0);
	z-index: 10;
	pointer-events: none;
	-webkit-transition: .3s cubic-bezier(.1, .57, .4, .97);
	transition: .3s cubic-bezier(.1, .57, .4, .97)
}

.navbar .close-div .close-menu {
	background: rgba(0, 0, 0, 0);
	pointer-events: all;
	color: #06c;
	font-size: .75em;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	float: right
}

.navbar .close-div .close-menu span {
	display: block;
	text-align: center;
	font-size: 2em
}

.navbar .close-div .close-menu span:before {
	margin-right: 0
}

.navbar .dropdown-menu {
	background: 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-top: 0
}

.navbar .dropdown-menu:before {
	display: none
}

.navbar .dropdown-menu .link-list-wrapper {
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 24px
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active {
	color: #1f5c99;
	text-decoration: underline
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
	color: #1f5c99
}

.navbar .dropdown-menu .link-list-wrapper ul li a i,
.navbar .dropdown-menu .link-list-wrapper ul li a span {
	color: #06c
}

.navbar .dropdown-menu .link-list-wrapper ul li h3 {
	text-transform: uppercase;
	font-size: .833em;
	font-weight: 600;
	letter-spacing: 1px
}

.navbar .dropdown-menu .link-list-wrapper ul li .divider {
	width: 65px;
	background: #e6ecf2;
	margin-left: 24px
}

.custom-navbar-toggler {
	background: 0 0;
	border: none;
	cursor: pointer
}

.custom-navbar-toggler span {
	color: #fff;
	font-size: 1.625em
}

.custom-navbar-toggler svg {
	fill: #fff;
	width: 24px;
	height: 24px;
	display: block
}

.nav-item.megamenu>a:before {
	display: none
}

.inline-menu .link-list-wrapper .link-list,
.inline-menu .link-list-wrapper .link-list li a {
	position: relative
}

.inline-menu .link-list-wrapper .link-list li a i {
	color: #6aaaeb
}

.inline-menu .link-list-wrapper .link-list li a:after {
	content: '';
	display: block;
	width: 2px;
	background: #06c;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transition: .3s;
	transition: .3s;
	z-index: 1
}

.inline-menu .link-list-wrapper .link-list li a.active span {
	color: #06c;
	text-decoration: none
}

.inline-menu .link-list-wrapper .link-list li a[aria-expanded=true]:after {
	-webkit-transform: scaleY(1);
	transform: scaleY(1)
}

.inline-menu .link-list-wrapper .link-list:after {
	content: '';
	display: block;
	width: 2px;
	background: -webkit-gradient(linear, left top, left bottom, from(#e6ecf2), to(rgba(230, 236, 242, .3)));
	background: linear-gradient(to bottom, #e6ecf2 0, rgba(230, 236, 242, .3) 100%);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%
}

@media (min-width:992px) and (min-width:992px) {
	.navbar .navbar-collapsable .navbar-nav li.nav-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.navbar .navbar-collapsable .navbar-nav li.nav-item .nav-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-item-align: end;
		align-self: flex-end;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
}



.skiplinks {
	background-color: #0bd9d2;
	text-align: center
}

.skiplinks a {
	padding: .5rem;
	display: block;
	font-weight: 600;
	color: #5b6f82;
	text-decoration: underline
}

.affix-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0
}

.affix-bottom {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0
}

.affix-parent {
	position: relative
}

.sidebar-wrapper {
	padding: 24px 0
}

.sidebar-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 24px;
	padding-right: 24px
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a {
	line-height: 1em;
	padding-top: .8em;
	padding-bottom: .8em;
	font-size: 1em
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a i.it-expand {
	font-size: .8em;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[aria-expanded=true] i {
	-webkit-transform: translateY(-50%) scaleY(-1);
	transform: translateY(-50%) scaleY(-1)
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: 2px solid #06c
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active i,
.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active span {
	color: #06c;
	text-decoration: none
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a {
	font-size: .889em;
	padding-top: .7em;
	padding-bottom: .7em
}

.sidebar-wrapper .sidebar-linklist-wrapper.linklist-secondary:before {
	content: '';
	width: 65px;
	height: 1px;
	background: #e6ecf2;
	display: block;
	margin-left: 24px;
	margin-top: 24px
}

.sidebar-wrapper.it-line-right-side {
	border-right: 1px solid #d9dadb
}

.sidebar-wrapper.it-line-right-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-right: 2px solid #06c;
	border-left: none
}

.sidebar-wrapper.it-line-left-side {
	border-left: 1px solid #d9dadb
}

.sidebar-wrapper.theme-dark {
	background: #435a70
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper h3,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a i.it-expand,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a span,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a.left-icon i.left,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[aria-expanded=true] i,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse],
.sidebar-wrapper.theme-dark h3 {
	color: #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: 2px solid #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active i,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active span,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a span {
	color: #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper.linklist-secondary:before {
	background: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-right-side {
	border-right-color: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-right-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: none;
	border-right-color: #fff
}

.sidebar-wrapper.theme-dark.it-line-left-side {
	border-left-color: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-left-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left-color: #fff
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
	margin-top: 24px;
	line-height: 2.3em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
	margin-top: 24px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
	margin: .5em 48px;
	line-height: 1.2em;
	font-size: .833em;
	text-transform: uppercase;
	padding-bottom: .5em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
	margin-bottom: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
	margin-top: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
	margin-left: 6px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li:last-child.it-more a {
	margin-top: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
	width: 100%;
	background: #f5f6f7;
	padding-top: 16px;
	padding-bottom: 16px;
	margin-top: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
	font-weight: 600
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
	display: none
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
	background: 0 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
	padding-left: 42px;
	padding-right: 42px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
	display: block;
	width: 100%;
	margin-bottom: 12px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
	font-size: .889em
}

.navbar .dropdown-menu>.it-external {
	padding: 0
}

.navbar .dropdown-menu .margin-right-col .it-external {
	padding: 0 6px
}

.navbar .dropdown-menu .it-external {
	width: 100%
}

.navbar .dropdown-menu .it-external .link-list-wrapper .link-list li.it-more a:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .dropdown-menu .it-megamenu-footer {
	background: #f5f6f7;
	padding: 16px 0;
	margin-top: 16px
}

.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper {
	margin-bottom: 0
}

.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item span {
	display: inline;
	font-weight: 600
}

@media (min-width:992px) {
	.navbar .dropdown-menu .it-megamenu-footer {
		padding: 12px 24px;
		margin: 32px -24px -32px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item {
		padding-top: 16px;
		padding-bottom: 16px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item span {
		display: inline;
		font-weight: 600
	}
	.navbar .dropdown-menu .it-external {
		width: 100%;
		padding: 0
	}
	.navbar .dropdown-menu .it-external .link-list-wrapper .link-list li.it-more a:before {
		content: '';
		display: block;
		height: 1px;
		width: 65px;
		background: #e6ecf2;
		margin-bottom: 8px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col {
		min-height: 100%;
		margin-right: -31px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .row.max-height-col {
		margin-right: -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .margin-right-col {
		margin-right: 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
		background: #f5f6f7;
		padding-top: 32px;
		margin: -32px -24px -32px -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
		font-weight: 600
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
		height: 1px;
		display: block;
		background: #d9dadb;
		margin: 25px 0 16px 36px;
		width: 65px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
		background: 0 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
		display: block;
		width: 100%;
		margin-bottom: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
		font-size: .889em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
		padding: 32px 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
		margin-top: 5px;
		line-height: 1.2em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		content: '';
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:first-child .link-list-wrapper {
		margin-bottom: 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		min-height: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li a,
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		padding: .5em 24px;
		line-height: inherit
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		margin-top: 5px;
		margin-bottom: 5px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more {
		margin-top: auto
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
		margin-top: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
		font-size: .8em;
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px)
	}
}

.bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	height: 96px
}

.bottom-nav:before {
	content: '';
	position: absolute;
	height: 360px;
	width: 100%;
	top: 32px;
	z-index: 10;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, .25) 0, rgba(0, 0, 0, 0) 60%);
	border-radius: 50%;
	-webkit-transform: translateY(-50%) scalex(1.4) scaleY(.12);
	transform: translateY(-50%) scalex(1.4) scaleY(.12);
	opacity: .18
}

.bottom-nav ul {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 20;
	background-color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin: 0;
	height: 64px
}

.bottom-nav ul li {
	list-style-type: none;
	margin: 8px;
	text-align: center
}

.bottom-nav a {
	display: block;
	color: #5b6f82;
	text-decoration: none
}

.bottom-nav a .icon {
	fill: #5b6f82
}

.bottom-nav a.active {
	color: #06c
}

.bottom-nav a.active .icon {
	fill: #06c
}

.bottom-nav a:hover {
	text-decoration: none;
	color: #06c
}

.bottom-nav a:hover .icon {
	fill: #06c
}

.bottom-nav .it-ico {
	position: relative;
	font-size: 1.35rem;
	display: block;
	height: 32px
}

.bottom-nav .it-ico:before {
	margin-right: 0
}

.bottom-nav .badge-wrapper {
	position: relative
}

.bottom-nav .bottom-nav-alert,
.bottom-nav .bottom-nav-badge {
	display: block;
	position: absolute;
	background-color: #06c;
	border: 1px solid #fff
}

.bottom-nav .bottom-nav-badge {
	top: 0;
	right: 0;
	min-width: 18px;
	height: 18px;
	padding: 0 4px;
	color: #fff;
	font-size: .563rem;
	border-radius: 50%;
	font-style: normal
}

.bottom-nav .bottom-nav-alert {
	top: 4px;
	right: 8px;
	min-width: 10px;
	height: 10px;
	font-size: .563rem;
	border-radius: 50%
}

.bottom-nav .bottom-nav-label {
	margin-top: 6px;
	font-size: .688rem;
	line-height: 1;
	display: block;
	font-weight: 600
}

.navbar.it-navscroll-wrapper {
	background: #fff
}

.navbar.it-navscroll-wrapper .it-back-button {
	padding: 16px 24px;
	font-weight: 600;
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 10;
	display: none
}

.navbar.it-navscroll-wrapper .it-back-button i {
	-webkit-transform: scale(.8);
	transform: scale(.8);
	-webkit-transform-origin: center;
	transform-origin: center;
	display: inline-block;
	margin-right: 8px
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
	font-size: 1em;
	font-weight: 600;
	line-height: 1em;
	padding-top: .8em;
	padding-bottom: .8em
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
	border-left: 2px solid #06c
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
	color: #06c;
	text-decoration: none
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
	padding-top: 0;
	padding-bottom: 0
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
	font-weight: 400
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
	font-weight: 400;
	border-left: none
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
	text-decoration: underline;
	color: #17324d
}

.navbar.it-navscroll-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 24px;
	padding-right: 24px
}

.navbar.it-navscroll-wrapper .custom-navbar-toggler {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #06c;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 600;
	cursor: pointer
}

.navbar.it-navscroll-wrapper .custom-navbar-toggler span.it-list {
	color: #06c;
	margin-right: 8px
}

.navbar.it-navscroll-wrapper .menu-wrapper {
	padding: 80px 0 24px;
	right: 0
}

.navbar.it-bottom-navscroll {
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: auto
}

.navbar.it-top-navscroll {
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: auto
}

@media (min-width:992px) {
	.navbar.it-navscroll-wrapper {
		background: #fff
	}
	.navbar.it-navscroll-wrapper .it-back-button {
		display: none!important
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
		font-size: 1em;
		font-weight: 600;
		line-height: 1em;
		padding-top: .8em;
		padding-bottom: .8em
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
		border-left: 2px solid #06c
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
		color: #06c;
		text-decoration: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
		padding-top: 0;
		padding-bottom: 0
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
		font-weight: 400
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
		font-weight: 400;
		border-left: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
		text-decoration: underline;
		color: #17324d
	}
	.navbar.it-navscroll-wrapper h3 {
		font-weight: 600;
		margin-bottom: .8rem;
		font-size: .833em;
		margin-top: 5px;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-left: 24px;
		padding-right: 24px
	}
	.navbar.it-navscroll-wrapper .custom-navbar-toggler {
		display: none
	}
	.navbar.it-navscroll-wrapper .menu-wrapper {
		padding: 16px 0 24px;
		background: #fff
	}
	.navbar.it-bottom-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-bottom-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-top-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-top-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-left-side {
		border-left: 1px solid #d9dadb
	}
	.navbar.it-right-side {
		border-right: 1px solid #d9dadb
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

@media (max-width:992px) {
	.navbar.it-navscroll-wrapper.theme-dark-mobile {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

@media (max-width:992px) {
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper {
		background: #06c
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before,
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description p {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li a.nav-link {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-left-color: #fff
	}
	.navbar.theme-dark-mobile .close-div .close-menu {
		color: #fff
	}
	.navbar.theme-dark-mobile .dropdown-menu h3 {
		font-weight: 600
	}
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a h3,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a i,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a span,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li h3,
	.navbar.theme-dark-mobile .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li .divider {
		background: #7ab8f5
	}
}

@media (min-width:992px) {
	.form-newsletter button {
		display: inline-block;
		width: auto
	}
	.navbar.theme-light-desk {
		background: #fff;
		-webkit-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
		box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05)
	}
	.navbar.theme-light-desk .navbar-collapsable .menu-wrapper {
		background: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu a.nav-link:before {
		background-color: #06c
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-description p {
		color: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li a.nav-link {
		color: #06c
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-bottom-color: #06c
	}
	.navbar.theme-light-desk .dropdown-menu {
		background: #06c
	}
	.navbar.theme-light-desk .dropdown-menu h3 {
		font-weight: 600
	}
	.navbar.theme-light-desk .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.navbar.theme-light-desk .dropdown-menu:before {
		background: #06c
	}
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a h3,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a i,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a span,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li h3 {
		color: #fff
	}
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li .divider {
		background: #d9dadb
	}
}

@media (max-width:991.98px) {
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-xl {
		padding-right: 0;
		padding-left: 0
	}
}

@media (min-width:992px) {
	.navbar-expand-lg {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-lg .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.navbar-expand-lg .navbar-collapse {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
	.navbar-expand-lg .navbar-toggler {
		display: none
	}
}

.navbar .navbar-collapsable {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	display: none;
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper {
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 48px;
	left: 0;
	pointer-events: all;
	overflow-y: auto;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: .2s cubic-bezier(.29, .85, .5, .99);
	transition: .2s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
	padding: 24px 0 0;
	overflow: hidden
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
	padding-bottom: 0
}

.navbar .navbar-collapsable .navbar-nav {
	padding: 24px 0
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link {
	color: #06c;
	font-weight: 600;
	padding: 13px 24px;
	position: relative
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
	fill: #06c;
	-webkit-transition: .3s;
	transition: .3s
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
	border-left: 3px solid #06c
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link:hover:not(.active) {
	text-decoration: underline
}

.navbar .navbar-collapsable.expanded .menu-wrapper {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: .3s cubic-bezier(.29, .85, .5, .99);
	transition: .3s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable.expanded .close-div {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.navbar .close-div {
	/* -webkit-transform: translateX(-100%);
	transform: translateX(-100%); */
	position: fixed;
	left: auto;
	right: 2px;
	top: 24px;
	background: rgba(0, 0, 0, 0);
	z-index: 10;
	pointer-events: none;
	-webkit-transition: .3s cubic-bezier(.1, .57, .4, .97);
	transition: .3s cubic-bezier(.1, .57, .4, .97)
}

.navbar .close-div .close-menu {
	background: rgba(0, 0, 0, 0);
	pointer-events: all;
	color: #06c;
	font-size: .75em;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	float: right
}

.navbar .close-div .close-menu span {
	display: block;
	text-align: center;
	font-size: 2em
}

.navbar .close-div .close-menu span:before {
	margin-right: 0
}

.navbar .dropdown-menu {
	background: 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-top: 0
}

.navbar .dropdown-menu:before {
	display: none
}

.navbar .dropdown-menu .link-list-wrapper {
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 24px
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active {
	color: #1f5c99;
	text-decoration: underline
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
	color: #1f5c99
}

.navbar .dropdown-menu .link-list-wrapper ul li a i,
.navbar .dropdown-menu .link-list-wrapper ul li a span {
	color: #06c
}

.navbar .dropdown-menu .link-list-wrapper ul li h3 {
	text-transform: uppercase;
	font-size: .833em;
	font-weight: 600;
	letter-spacing: 1px
}

.navbar .dropdown-menu .link-list-wrapper ul li .divider {
	width: 65px;
	background: #e6ecf2;
	margin-left: 24px
}

.custom-navbar-toggler {
	background: 0 0;
	border: none;
	cursor: pointer
}

.custom-navbar-toggler span {
	color: #fff;
	font-size: 1.625em
}

.custom-navbar-toggler svg {
	fill: #fff;
	width: 24px;
	height: 24px;
	display: block
}

.nav-item.megamenu>a:before {
	display: none
}

.inline-menu .link-list-wrapper .link-list,
.inline-menu .link-list-wrapper .link-list li a {
	position: relative
}

.inline-menu .link-list-wrapper .link-list li a i {
	color: #6aaaeb
}

.inline-menu .link-list-wrapper .link-list li a:after {
	content: '';
	display: block;
	width: 2px;
	background: #06c;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transition: .3s;
	transition: .3s;
	z-index: 1
}

.inline-menu .link-list-wrapper .link-list li a.active span {
	color: #06c;
	text-decoration: none
}

.inline-menu .link-list-wrapper .link-list li a[aria-expanded=true]:after {
	-webkit-transform: scaleY(1);
	transform: scaleY(1)
}

.inline-menu .link-list-wrapper .link-list:after {
	content: '';
	display: block;
	width: 2px;
	background: -webkit-gradient(linear, left top, left bottom, from(#e6ecf2), to(rgba(230, 236, 242, .3)));
	background: linear-gradient(to bottom, #e6ecf2 0, rgba(230, 236, 242, .3) 100%);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%
}

@media (min-width:992px) and (min-width:992px) {
	.navbar .navbar-collapsable .navbar-nav li.nav-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.navbar .navbar-collapsable .navbar-nav li.nav-item .nav-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-item-align: end;
		align-self: flex-end;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
	margin-top: 24px;
	line-height: 2.3em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
	margin-top: 24px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
	margin: .5em 48px;
	line-height: 1.2em;
	font-size: .833em;
	text-transform: uppercase;
	padding-bottom: .5em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
	margin-bottom: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
	margin-top: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
	margin-left: 6px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li:last-child.it-more a {
	margin-top: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
	width: 100%;
	background: #f5f6f7;
	padding-top: 16px;
	padding-bottom: 16px;
	margin-top: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
	font-weight: 600
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
	display: none
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
	background: 0 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
	padding-left: 42px;
	padding-right: 42px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
	display: block;
	width: 100%;
	margin-bottom: 12px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
	font-size: .889em
}

@media (min-width:992px) {
	.navbar .dropdown-menu .it-megamenu-footer {
		padding: 12px 24px;
		margin: 32px -24px -32px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item {
		padding-top: 16px;
		padding-bottom: 16px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item span {
		display: inline;
		font-weight: 600
	}
	.navbar .dropdown-menu .it-external {
		width: 100%;
		padding: 0
	}
	.navbar .dropdown-menu .it-external .link-list-wrapper .link-list li.it-more a:before {
		content: '';
		display: block;
		height: 1px;
		width: 65px;
		background: #e6ecf2;
		margin-bottom: 8px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col {
		min-height: 100%;
		margin-right: -31px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .row.max-height-col {
		margin-right: -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .margin-right-col {
		margin-right: 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
		background: #f5f6f7;
		padding-top: 32px;
		margin: -32px -24px -32px -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
		font-weight: 600
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
		height: 1px;
		display: block;
		background: #d9dadb;
		margin: 25px 0 16px 36px;
		width: 65px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
		background: 0 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
		display: block;
		width: 100%;
		margin-bottom: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
		font-size: .889em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
		padding: 32px 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
		margin-top: 5px;
		line-height: 1.2em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		content: '';
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:first-child .link-list-wrapper {
		margin-bottom: 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		min-height: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li a,
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		padding: .5em 24px;
		line-height: inherit
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		margin-top: 5px;
		margin-bottom: 5px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more {
		margin-top: auto
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
		margin-top: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
		font-size: .8em;
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px)
	}
}

@media (min-width:992px) {
	.navbar.it-navscroll-wrapper {
		background: #fff
	}
	.navbar.it-navscroll-wrapper .it-back-button {
		display: none!important
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
		font-size: 1em;
		font-weight: 600;
		line-height: 1em;
		padding-top: .8em;
		padding-bottom: .8em
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
		border-left: 2px solid #06c
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
		color: #06c;
		text-decoration: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
		padding-top: 0;
		padding-bottom: 0
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
		font-weight: 400
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
		font-weight: 400;
		border-left: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
		text-decoration: underline;
		color: #17324d
	}
	.navbar.it-navscroll-wrapper h3 {
		font-weight: 600;
		margin-bottom: .8rem;
		font-size: .833em;
		margin-top: 5px;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-left: 24px;
		padding-right: 24px
	}
	.navbar.it-navscroll-wrapper .custom-navbar-toggler {
		display: none
	}
	.navbar.it-navscroll-wrapper .menu-wrapper {
		padding: 16px 0 24px;
		background: #fff
	}
	.navbar.it-bottom-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-bottom-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-top-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-top-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-left-side {
		border-left: 1px solid #d9dadb
	}
	.navbar.it-right-side {
		border-right: 1px solid #d9dadb
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

@media (max-width:992px) {
	.navbar.it-navscroll-wrapper.theme-dark-mobile {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

@media (min-width:992px) {
	.it-header-center-wrapper {
		height: 120px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 6px
	}
	.it-header-center-wrapper.it-small-header {
		height: 104px
	}
	.it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h2 {
		font-size: 1.333em
	}
	.it-header-center-wrapper.it-small-header .it-header-center-content-wrapper .it-brand-wrapper a h3 {
		font-size: .778em;
		margin-top: 4px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper {
		padding-left: 18px;
		padding-right: 18px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper {
		margin-left: 0
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
		font-size: 1.3em;
		margin-bottom: 0;
		font-weight: 600
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h3 {
		font-size: .889em;
		font-weight: 400;
		margin-bottom: 0
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a:hover {
		text-decoration: none
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
		width: 10em;
    	height: 80%;
		margin-right: 16px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: .889em
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul .icon {
		width: 24px;
		height: 24px;
		margin-left: 16px
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-right-zone .it-socials ul a:hover svg {
		fill: #f2f2f2
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 80px;
		font-size: .889em
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		display: block;
		background: #fff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 16px;
		-webkit-transition: .3s;
		transition: .3s
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #f2f2f2
	}
	.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #06c;
		width: 24px;
		height: 24px
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-right-zone .it-socials ul a:hover svg {
		fill: #0059b3
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a {
		background: #06c
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #fff
	}
	.it-header-center-wrapper.theme-light .it-header-center-content-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #0059b3
	}
	.it-header-navbar-wrapper {
		background: #06c
	}
	.it-header-navbar-wrapper .nav-item.megamenu>a.dropdown-toggle:before {
		bottom: -14px
	}
	.it-header-navbar-wrapper nav a {
		text-decoration: none
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper {
		background: 0 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav {
		padding: 0
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav.navbar-secondary {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper .navbar-nav.navbar-secondary a {
		font-size: .85em;
		line-height: 1.6
	}
	.it-header-navbar-wrapper.theme-light-desk {
		background: #fff;
		-webkit-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
		box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05)
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
		fill: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar {
		background: 0 0
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .menu-wrapper {
		background: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu a.nav-link:before {
		background-color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.megamenu .it-description p {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link {
		color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-bottom-color: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu {
		background: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu h3 {
		font-weight: 600
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu:before {
		background: #06c
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a h3,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a i,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a span,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li h3 {
		color: #fff
	}
	.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu .link-list-wrapper ul li .divider {
		background: #d9dadb
	}
}

.it-header-wrapper .it-nav-wrapper {
	position: relative
}

.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
	padding-left: 32px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
	-webkit-transition: padding-top .3s;
	transition: padding-top .3s;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -22px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
	padding-left: 18px;
	padding-right: 18px
}

.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav .custom-navbar-toggler {
	padding: 0;
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
	cursor: pointer
}

.it-header-wrapper.it-header-sticky.is-sticky .it-nav-wrapper {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10
}

.it-header-wrapper.it-header-sticky.is-sticky~* .owl-carousel {
	z-index: auto
}

@media (min-width:992px) {
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-center-wrapper,
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-slim-wrapper {
		display: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper {
		position: fixed;
		width: 100%;
		top: 0
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper {
		-ms-flex-item-align: center;
		align-self: center
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned .it-brand-text {
		display: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a {
		color: #fff
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a:hover {
		text-decoration: none
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-brand-wrapper.cloned a .icon {
		fill: #fff;
		width: 90px;
		height: 41px;
		margin-right: 16px;
		-ms-flex-negative: 0;
		flex-shrink: 0
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-item-align: center;
		align-self: center;
		margin-left: 26.66667px;
		font-size: .889em;
		color: #fff
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		display: block;
		background: #fff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-left: 16px;
		-webkit-transition: .3s;
		transition: .3s
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon:hover {
		background: #f2f2f2
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper a.rounded-icon svg {
		fill: #06c;
		width: 24px;
		height: 24px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper.cloned a {
		width: 35px;
		height: 35px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .it-search-wrapper.cloned a svg {
		width: 20px;
		height: 20px
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .navbar-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1
	}
	.it-header-wrapper.it-header-sticky.is-sticky .it-header-navbar-wrapper .menu-wrapper .navbar-nav.navbar-secondary {
		display: none
	}
	.it-header-wrapper .it-nav-wrapper {
		position: relative
	}
	.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
		padding-left: 0
	}
	.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
		position: inherit;
		left: auto;
		top: inherit;
		margin-top: 0
	}
	.it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
		padding-left: 18px;
		padding-right: 18px
	}
	.it-header-wrapper .navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
		left: 0;
		right: 0
	}
}

.it-footer-main {
	background-color: #036;
	color: #fff;
	font-size: 16px
}

.it-footer-main h4 a {
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 16px
}

.it-footer-main .link-list-wrapper ul li a {
	padding: 0;
	font-size: 1em;
	line-height: 2em;
	color: #fff
}

.it-footer-main .link-list-wrapper ul li a:hover:not(.disabled) {
	text-decoration: underline
}

.it-footer-main .it-brand-wrapper {
	padding: 32px 0
}

.it-footer-main .it-brand-wrapper .it-brand-text {
	padding-right: 24px
}

.it-footer-main .it-brand-wrapper a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #fff;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none
}

.it-footer-main .it-brand-wrapper a h2 {
	font-size: 1.25em;
	margin-bottom: 0;
	font-weight: 600;
	letter-spacing: unset;
	line-height: 1.1
}

.it-footer-main .it-brand-wrapper a h3 {
	font-size: .889em;
	font-weight: 400;
	margin-bottom: 0
}

.it-footer-main .it-brand-wrapper a:hover {
	text-decoration: none
}

.it-footer-main .it-brand-wrapper a .icon {
	fill: #fff;
	width: 48px;
	height: 48px;
	margin-right: 8px;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.it-footer-main section {
	padding: 0 16px
}

.it-footer-main label {
	font-weight: 600
}

.it-footer-small-prints {
	background-color: #001a33
}

.it-footer-small-prints ul.it-footer-small-prints-list {
	padding: 1.5rem 1rem
}

.it-footer-small-prints ul.it-footer-small-prints-list li {
	padding: .5rem 0
}

.it-footer-small-prints ul.it-footer-small-prints-list li:first-child {
	padding-top: 0
}

.it-footer-small-prints ul.it-footer-small-prints-list li:last-child {
	padding-bottom: 0
}

.it-footer-small-prints a,
.it-footer-small-prints a:hover:not(.disabled) {
	color: #fff;
	font-size: 15px;
	text-decoration: none
}

.form-newsletter input[type=email] {
	font-size: 1em
}

.form-newsletter input[type=email]~* {
	margin-top: 1em
}

.form-newsletter button {
	display: block;
	width: 100%
}

@media (min-width:768px) {
	.it-footer-small-prints ul.it-footer-small-prints-list li {
		padding: 0 1rem
	}
	.it-footer-small-prints ul.it-footer-small-prints-list li:first-child {
		padding-left: 0
	}
	.it-footer-small-prints ul.it-footer-small-prints-list li:last-child {
		padding-right: 0
	}
}

@media (max-width:992px) {
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper {
		background: #06c
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before,
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description p {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		background: #7ab8f5
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li a.nav-link {
		color: #fff
	}
	.navbar.theme-dark-mobile .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-left-color: #fff
	}
	.navbar.theme-dark-mobile .close-div .close-menu {
		color: #fff
	}
	.navbar.theme-dark-mobile .dropdown-menu h3 {
		font-weight: 600
	}
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a h3,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a i,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a span,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li h3,
	.navbar.theme-dark-mobile .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.navbar.theme-dark-mobile .dropdown-menu .link-list-wrapper ul li .divider {
		background: #7ab8f5
	}
}

@media (min-width:992px) {
	.form-newsletter button {
		display: inline-block;
		width: auto
	}
	.navbar.theme-light-desk {
		background: #fff;
		-webkit-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
		box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05)
	}
	.navbar.theme-light-desk .navbar-collapsable .menu-wrapper {
		background: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu .link-list h3 {
		color: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-external .link-list-wrapper .link-list li.it-more a:before {
		background: #7ab8f5
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu a.nav-link:before {
		background-color: #06c
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical,
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-megamenu-footer {
		background: #0059b3
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .dropdown-menu .it-vertical .link-list-wrapper .link-list li .divider {
		background: #7ab8f5
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li.megamenu .it-description p {
		color: #fff
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li a.nav-link {
		color: #06c
	}
	.navbar.theme-light-desk .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-bottom-color: #06c
	}
	.navbar.theme-light-desk .dropdown-menu {
		background: #06c
	}
	.navbar.theme-light-desk .dropdown-menu h3 {
		font-weight: 600
	}
	.navbar.theme-light-desk .dropdown-menu h3.it-heading-megacolumn {
		color: #fff
	}
	.navbar.theme-light-desk .dropdown-menu:before {
		background: #06c
	}
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a h3,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a i,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a span,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a.list-item.active,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li a.list-item.active span,
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li h3 {
		color: #fff
	}
	.navbar.theme-light-desk .dropdown-menu .link-list-wrapper ul li .divider {
		background: #d9dadb
	}
}

.navbar {
	background: #06c
}

.navbar .overlay {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .6);
	display: none
}

.navbar .navbar-collapsable {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	display: none;
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper {
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 48px;
	left: 0;
	pointer-events: all;
	overflow-y: auto;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: .2s cubic-bezier(.29, .85, .5, .99);
	transition: .2s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
	padding: 24px 0 0;
	overflow: hidden
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
	padding-bottom: 0
}

.navbar .navbar-collapsable .navbar-nav {
	padding: 24px 0
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link {
	color: #06c;
	font-weight: 600;
	padding: 13px 24px;
	position: relative
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
	fill: #06c;
	-webkit-transition: .3s;
	transition: .3s
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
	border-left: 3px solid #06c
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link:hover:not(.active) {
	text-decoration: underline
}

.navbar .navbar-collapsable.expanded .menu-wrapper {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: .3s cubic-bezier(.29, .85, .5, .99);
	transition: .3s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable.expanded .close-div {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.navbar .close-div {
	/* -webkit-transform: translateX(-100%);
	transform: translateX(-100%); */
	position: fixed;
	left: auto;
	right: 2px;
	top: 24px;
	background: rgba(0, 0, 0, 0);
	z-index: 10;
	pointer-events: none;
	-webkit-transition: .3s cubic-bezier(.1, .57, .4, .97);
	transition: .3s cubic-bezier(.1, .57, .4, .97)
}

.navbar .close-div .close-menu {
	background: rgba(0, 0, 0, 0);
	pointer-events: all;
	color: #06c;
	font-size: .75em;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	float: right
}

.navbar .close-div .close-menu span {
	display: block;
	text-align: center;
	font-size: 2em
}

.navbar .close-div .close-menu span:before {
	margin-right: 0
}

.navbar .dropdown-menu {
	background: 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-top: 0
}

.navbar .dropdown-menu:before {
	display: none
}

.navbar .dropdown-menu .link-list-wrapper {
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 24px
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active {
	color: #1f5c99;
	text-decoration: underline
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
	color: #1f5c99
}

.navbar .dropdown-menu .link-list-wrapper ul li a i,
.navbar .dropdown-menu .link-list-wrapper ul li a span {
	color: #06c
}

.navbar .dropdown-menu .link-list-wrapper ul li h3 {
	text-transform: uppercase;
	font-size: .833em;
	font-weight: 600;
	letter-spacing: 1px
}

.navbar .dropdown-menu .link-list-wrapper ul li .divider {
	width: 65px;
	background: #e6ecf2;
	margin-left: 24px
}

.navbar {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 8px 16px
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.navbar-brand {
	display: inline-block;
	padding-top: .5rem;
	padding-bottom: .5rem;
	margin-right: 16px;
	font-size: 1rem;
	line-height: inherit;
	white-space: nowrap
}

.form-check [type=checkbox]:focus+label.navbar-brand,
.form-check [type=radio]:focus+label.navbar-brand,
.navbar-brand:focus,
.navbar-brand:hover,
.toggles label input[type=checkbox]:focus+.navbar-brand.lever {
	text-decoration: none
}

.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none
}

.navbar-text {
	display: inline-block;
	padding-top: .5rem;
	padding-bottom: .5rem
}

.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0
}

.form-check [type=checkbox]:focus+label.navbar-toggler,
.form-check [type=radio]:focus+label.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:hover,
.toggles label input[type=checkbox]:focus+.navbar-toggler.lever {
	text-decoration: none
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: center center/100% 100% no-repeat
}

@media (max-width:575.98px) {
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-xl {
		padding-right: 0;
		padding-left: 0
	}
}

@media (min-width:576px) {
	.navbar-expand-sm {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-sm .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.navbar-expand-sm .navbar-collapse {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
	.navbar-expand-sm .navbar-toggler {
		display: none
	}
}

@media (max-width:767.98px) {
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-xl {
		padding-right: 0;
		padding-left: 0
	}
}

@media (min-width:768px) {
	.navbar-expand-md {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-md .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.navbar-expand-md .navbar-collapse {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
	.navbar-expand-md .navbar-toggler {
		display: none
	}
}

@media (max-width:991.98px) {
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-xl {
		padding-right: 0;
		padding-left: 0
	}
}

@media (min-width:992px) {
	.navbar-expand-lg {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-lg .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.navbar-expand-lg .navbar-collapse {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
	.navbar-expand-lg .navbar-toggler {
		display: none
	}
}

@media (max-width:1199.98px) {
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-xl {
		padding-right: 0;
		padding-left: 0
	}
}

@media (min-width:1200px) {
	.dropdown-menu-xl-left {
		right: auto;
		left: 0
	}
	.dropdown-menu-xl-right {
		right: 0;
		left: auto
	}
	.navbar-expand-xl {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-xl .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-xl {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.navbar-expand-xl .navbar-collapse {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
	.navbar-expand-xl .navbar-toggler {
		display: none
	}
}

.navbar-expand {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.navbar-expand .navbar-nav {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: .5rem;
	padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
	padding-right: 0;
	padding-left: 0;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
	display: -webkit-box!important;
	display: -ms-flexbox!important;
	display: flex!important;
	-ms-flex-preferred-size: auto;
	flex-basis: auto
}

.navbar-expand .navbar-toggler {
	display: none
}

.row.row-column-border>[class^=col-] .navbar {
	padding: 0
}

.row.row-column-border>[class^=col-] .navbar .menu-wrapper .nav-link {
	padding-right: 0
}

.row.row-column-menu-left>[class^=col-]:first-child,
.row.row-column-menu-right>[class^=col-]:last-child {
	padding: 1rem 0
}

.row.row-card {
	background-color: #fff
}

@media (min-width:992px) {
	.row.row-column-border {
		margin-top: 1rem
	}
	.row.row-column-border>[class^=col-] {
		padding: 3rem;
		border-top: none;
		border-left: 1px solid #b1b1b3
	}
	.row.row-column-border>[class^=col-]:first-child {
		border: none;
		padding-left: 0
	}
	.row.row-column-border>[class^=col-] .navbar .menu-wrapper,
	.row.row-column-menu-left>[class^=col-]:first-child,
	.row.row-column-menu-right>[class^=col-]:last-child {
		padding: 0
	}
}

.navbar {
	background: #06c
}

.navbar .overlay {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .6);
	display: none
}

.navbar .navbar-collapsable {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	display: none;
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
	padding: 24px 0 0;
	overflow: hidden
}

.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
	padding-bottom: 0
}

.navbar .navbar-collapsable .navbar-nav {
	padding: 24px 0
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link {
	color: #06c;
	font-weight: 600;
	padding: 13px 24px;
	position: relative
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
	fill: #06c;
	-webkit-transition: .3s;
	transition: .3s
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle[aria-expanded=true] .icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1)
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
	border-left: 3px solid #06c
}

.navbar .navbar-collapsable .navbar-nav li a.nav-link:hover:not(.active) {
	text-decoration: underline
}

.navbar .navbar-collapsable.expanded .menu-wrapper {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: .3s cubic-bezier(.29, .85, .5, .99);
	transition: .3s cubic-bezier(.29, .85, .5, .99)
}

.navbar .navbar-collapsable.expanded .close-div {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.navbar .close-div {
	/* -webkit-transform: translateX(-100%);
	transform: translateX(-100%); */
	position: fixed;
	left: auto;
	right: 2px;
	top: 24px;
	background: rgba(0, 0, 0, 0);
	z-index: 10;
	pointer-events: none;
	-webkit-transition: .3s cubic-bezier(.1, .57, .4, .97);
	transition: .3s cubic-bezier(.1, .57, .4, .97)
}

.navbar .close-div .close-menu {
	background: rgba(0, 0, 0, 0);
	pointer-events: all;
	color: #06c;
	font-size: .75em;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	float: right
}

.navbar .close-div .close-menu span {
	display: block;
	text-align: center;
	font-size: 2em
}

.navbar .close-div .close-menu span:before {
	margin-right: 0
}

.navbar .dropdown-menu {
	background: 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-top: 0
}

.navbar .dropdown-menu:before {
	display: none
}

.navbar .dropdown-menu .link-list-wrapper {
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 24px
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active {
	color: #1f5c99;
	text-decoration: underline
}

.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
	color: #1f5c99
}

.navbar .dropdown-menu .link-list-wrapper ul li a i,
.navbar .dropdown-menu .link-list-wrapper ul li a span {
	color: #06c
}

.navbar .dropdown-menu .link-list-wrapper ul li h3 {
	text-transform: uppercase;
	font-size: .833em;
	font-weight: 600;
	letter-spacing: 1px
}

.navbar .dropdown-menu .link-list-wrapper ul li .divider {
	width: 65px;
	background: #e6ecf2;
	margin-left: 24px
}

.custom-navbar-toggler {
	background: 0 0;
	border: none;
	cursor: pointer
}

.custom-navbar-toggler span {
	color: #fff;
	font-size: 1.625em
}

.custom-navbar-toggler svg {
	fill: #fff;
	width: 24px;
	height: 24px;
	display: block
}

.nav-item.megamenu>a:before {
	display: none
}

.inline-menu .link-list-wrapper .link-list,
.inline-menu .link-list-wrapper .link-list li a {
	position: relative
}

.inline-menu .link-list-wrapper .link-list li a i {
	color: #6aaaeb
}

.inline-menu .link-list-wrapper .link-list li a:after {
	content: '';
	display: block;
	width: 2px;
	background: #06c;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transition: .3s;
	transition: .3s;
	z-index: 1
}

.inline-menu .link-list-wrapper .link-list li a.active span {
	color: #06c;
	text-decoration: none
}

.inline-menu .link-list-wrapper .link-list li a[aria-expanded=true]:after {
	-webkit-transform: scaleY(1);
	transform: scaleY(1)
}

.inline-menu .link-list-wrapper .link-list:after {
	content: '';
	display: block;
	width: 2px;
	background: -webkit-gradient(linear, left top, left bottom, from(#e6ecf2), to(rgba(230, 236, 242, .3)));
	background: linear-gradient(to bottom, #e6ecf2 0, rgba(230, 236, 242, .3) 100%);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%
}

@media (min-width:992px) and (min-width:992px) {
	.navbar .navbar-collapsable .navbar-nav li.nav-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.navbar .navbar-collapsable .navbar-nav li.nav-item .nav-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-item-align: end;
		align-self: flex-end;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
}

@media (min-width:992px) {
	.navbar {
		background: #06c;
		padding: 0
	}
	.navbar .navbar-collapsable {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
		bottom: auto;
		z-index: auto;
		background: 0 0;
		display: block!important;
		-webkit-transition: none;
		transition: none
	}
	.navbar .navbar-collapsable .overlay {
		display: none!important
	}
	.navbar .navbar-collapsable .menu-wrapper {
		position: inherit;
		top: auto;
		bottom: auto;
		right: auto;
		left: auto;
		overflow-y: visible;
		-webkit-transform: none;
		transform: none;
		-webkit-transition: none;
		transition: none;
		background: #06c
	}
	.navbar .navbar-collapsable .menu-wrapper .navbar-nav {
		margin-top: 0;
		padding: 0 24px;
		overflow: inherit
	}
	.navbar .navbar-collapsable .menu-wrapper .navbar-nav .dropdown-menu {
		padding-bottom: .5rem
	}
	.navbar .navbar-collapsable .navbar-nav {
		margin-top: 0;
		padding: 0 24px
	}
	.navbar .navbar-collapsable .navbar-nav li a.nav-link {
		font-weight: 400;
		padding: 13px 24px;
		color: #fff;
		border-bottom: 3px solid transparent
	}
	.navbar .navbar-collapsable .navbar-nav li a.nav-link.dropdown-toggle svg {
		fill: #fff
	}
	.navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
		border-left: 0;
		border-color: #fff;
		background: 0 0
	}
	.navbar .navbar-collapsable .navbar-nav li a.nav-link.disabled {
		opacity: .5;
		cursor: not-allowed
	}
	.navbar .close-div {
		display: none
	}
	.navbar .dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		float: left;
		min-width: 10rem;
		padding: .5rem 0 0;
		margin: .125rem 0 0;
		font-size: 1rem;
		color: #19191a;
		text-align: left;
		list-style: none;
		background-color: #fff;
		background-clip: padding-box;
		border: 0 solid transparent;
		border-radius: 0 0 4px 4px;
		-webkit-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05);
		box-shadow: 0 20px 30px 5px rgba(0, 0, 0, .05)
	}
	.navbar .dropdown-menu.show {
		-webkit-animation: .3s forwards dropdownFadeIn;
		animation: .3s forwards dropdownFadeIn;
		top: calc(100% - 16px)
	}
	.navbar .dropdown-menu:before {
		display: block
	}
	.navbar .dropdown-menu .link-list-wrapper {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0
	}
	.navbar .dropdown-menu .link-list-wrapper ul li a h3,
	.navbar .dropdown-menu .link-list-wrapper ul li a i,
	.navbar .dropdown-menu .link-list-wrapper ul li a span {
		color: #06c
	}
	.navbar .dropdown-menu .link-list-wrapper ul li h3 {
		color: #19191a
	}
	.navbar .dropdown-toggle {
		display: block;
		-webkit-box-pack: inherit;
		-ms-flex-pack: inherit;
		justify-content: inherit
	}
	.custom-navbar-toggler {
		display: none
	}
	.has-megamenu .navbar-collapsable {
		width: 100%
	}
	.nav-item.megamenu {
		position: static
	}
	.nav-item.megamenu>a.dropdown-toggle {
		position: relative
	}
	.nav-item.megamenu>a.dropdown-toggle:before {
		content: '';
		position: absolute;
		top: auto;
		bottom: -12px;
		left: 24px;
		width: 18px;
		height: 18px;
		border-radius: 4px;
		background-color: #fff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		opacity: 0;
		display: block;
		-webkit-transition: opacity .15s cubic-bezier(.1, .57, .36, .99);
		transition: opacity .15s cubic-bezier(.1, .57, .36, .99)
	}
	.nav-item.megamenu.show a.dropdown-toggle:before {
		opacity: 1
	}
	.megamenu .dropdown-menu {
		min-width: auto;
		left: 24px;
		right: 24px;
		-webkit-animation-timing-function: cubic-bezier(.1, .57, .36, .99);
		animation-timing-function: cubic-bezier(.1, .57, .36, .99)
	}
	.megamenu .dropdown-menu:before {
		display: none
	}
	.megamenu .dropdown-menu.show {
		-webkit-animation: .15s forwards megamenuFadeIn;
		animation: .15s forwards megamenuFadeIn
	}
}

.skiplinks {
	background-color: #0bd9d2;
	text-align: center
}

.skiplinks a {
	padding: .5rem;
	display: block;
	font-weight: 600;
	color: #5b6f82;
	text-decoration: underline
}

.affix-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0
}

.affix-bottom {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0
}

.affix-parent {
	position: relative
}

.sidebar-wrapper {
	padding: 24px 0
}

.sidebar-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 24px;
	padding-right: 24px
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a {
	line-height: 1em;
	padding-top: .8em;
	padding-bottom: .8em;
	font-size: 1em
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a i.it-expand {
	font-size: .8em;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[aria-expanded=true] i {
	-webkit-transform: translateY(-50%) scaleY(-1);
	transform: translateY(-50%) scaleY(-1)
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: 2px solid #06c
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active i,
.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active span {
	color: #06c;
	text-decoration: none
}

.sidebar-wrapper .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a {
	font-size: .889em;
	padding-top: .7em;
	padding-bottom: .7em
}

.sidebar-wrapper .sidebar-linklist-wrapper.linklist-secondary:before {
	content: '';
	width: 65px;
	height: 1px;
	background: #e6ecf2;
	display: block;
	margin-left: 24px;
	margin-top: 24px
}

.sidebar-wrapper.it-line-right-side {
	border-right: 1px solid #d9dadb
}

.sidebar-wrapper.it-line-right-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-right: 2px solid #06c;
	border-left: none
}

.sidebar-wrapper.it-line-left-side {
	border-left: 1px solid #d9dadb
}

.sidebar-wrapper.theme-dark {
	background: #435a70
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper h3,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a i.it-expand,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a span,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a.left-icon i.left,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[aria-expanded=true] i,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse],
.sidebar-wrapper.theme-dark h3 {
	color: #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: 2px solid #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active i,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active span,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a,
.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper .link-list-wrapper ul.link-sublist li a span {
	color: #fff
}

.sidebar-wrapper.theme-dark .sidebar-linklist-wrapper.linklist-secondary:before {
	background: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-right-side {
	border-right-color: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-right-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left: none;
	border-right-color: #fff
}

.sidebar-wrapper.theme-dark.it-line-left-side {
	border-left-color: rgba(229, 229, 229, .3)
}

.sidebar-wrapper.theme-dark.it-line-left-side .sidebar-linklist-wrapper .link-list-wrapper ul li a[data-toggle=collapse].active {
	border-left-color: #fff
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
	margin-top: 24px;
	line-height: 2.3em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
	margin-top: 24px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
	margin: .5em 48px;
	line-height: 1.2em;
	font-size: .833em;
	text-transform: uppercase;
	padding-bottom: .5em
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
	margin-bottom: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
	margin-top: 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
	margin-left: 6px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li:last-child.it-more a {
	margin-top: 8px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
	width: 100%;
	background: #f5f6f7;
	padding-top: 16px;
	padding-bottom: 16px;
	margin-top: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
	width: 100%
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
	font-weight: 600
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
	display: none
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
	background: 0 0
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
	padding-left: 42px;
	padding-right: 42px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 16px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
	display: block;
	width: 100%;
	margin-bottom: 12px
}

.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
	font-size: .889em
}

.navbar .dropdown-menu>.it-external {
	padding: 0
}

.navbar .dropdown-menu .margin-right-col .it-external {
	padding: 0 6px
}

.navbar .dropdown-menu .it-external {
	width: 100%
}

.navbar .dropdown-menu .it-external .link-list-wrapper .link-list li.it-more a:before {
	content: '';
	display: block;
	height: 1px;
	width: 65px;
	background: #e6ecf2;
	margin-bottom: 8px
}

.navbar .dropdown-menu .it-megamenu-footer {
	background: #f5f6f7;
	padding: 16px 0;
	margin-top: 16px
}

.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper {
	margin-bottom: 0
}

.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item span {
	display: inline;
	font-weight: 600
}

@media (min-width:992px) {
	.navbar .dropdown-menu .it-megamenu-footer {
		padding: 12px 24px;
		margin: 32px -24px -32px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item {
		padding-top: 16px;
		padding-bottom: 16px
	}
	.navbar .dropdown-menu .it-megamenu-footer .link-list-wrapper .link-list li a.list-item span {
		display: inline;
		font-weight: 600
	}
	.navbar .dropdown-menu .it-external {
		width: 100%;
		padding: 0
	}
	.navbar .dropdown-menu .it-external .link-list-wrapper .link-list li.it-more a:before {
		content: '';
		display: block;
		height: 1px;
		width: 65px;
		background: #e6ecf2;
		margin-bottom: 8px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col {
		min-height: 100%;
		margin-right: -31px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .row.max-height-col {
		margin-right: -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .row.max-height-col .margin-right-col {
		margin-right: 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical {
		background: #f5f6f7;
		padding-top: 32px;
		margin: -32px -24px -32px -6px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li a span {
		font-weight: 600
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical .link-list-wrapper ul.link-list li .divider {
		height: 1px;
		display: block;
		background: #d9dadb;
		margin: 25px 0 16px 36px;
		width: 65px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description {
		background: 0 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content {
		padding-left: 36px;
		padding-right: 36px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content img {
		display: block;
		width: 100%;
		margin-bottom: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .it-vertical.it-description .description-content p {
		font-size: .889em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu {
		padding: 32px 24px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3 {
		margin-top: 5px;
		line-height: 1.2em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .link-list-wrapper h3:before {
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:not(:first-child) .it-heading-megacolumn:before {
		content: '';
		display: none
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .col-12:first-child .link-list-wrapper {
		margin-bottom: 0
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu h3.it-heading-megacolumn {
		margin: .5em 24px;
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		border-bottom: 1px solid #e6ecf2;
		padding-bottom: .5em
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		min-height: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li {
		width: 100%
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li a,
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		padding: .5em 24px;
		line-height: inherit
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li h3 {
		line-height: 1.2em;
		font-size: .833em;
		text-transform: uppercase;
		margin-top: 5px;
		margin-bottom: 5px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more {
		margin-top: auto
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a {
		margin-top: 12px
	}
	.navbar .navbar-collapsable .menu-wrapper .nav-item.megamenu .dropdown-menu .link-list-wrapper ul.link-list li.it-more a i {
		font-size: .8em;
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px)
	}
}

.bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	height: 96px
}

.bottom-nav:before {
	content: '';
	position: absolute;
	height: 360px;
	width: 100%;
	top: 32px;
	z-index: 10;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, .25) 0, rgba(0, 0, 0, 0) 60%);
	border-radius: 50%;
	-webkit-transform: translateY(-50%) scalex(1.4) scaleY(.12);
	transform: translateY(-50%) scalex(1.4) scaleY(.12);
	opacity: .18
}

.bottom-nav ul {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 20;
	background-color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin: 0;
	height: 64px
}

.bottom-nav ul li {
	list-style-type: none;
	margin: 8px;
	text-align: center
}

.bottom-nav a {
	display: block;
	color: #5b6f82;
	text-decoration: none
}

.bottom-nav a .icon {
	fill: #5b6f82
}

.bottom-nav a.active {
	color: #06c
}

.bottom-nav a.active .icon {
	fill: #06c
}

.bottom-nav a:hover {
	text-decoration: none;
	color: #06c
}

.bottom-nav a:hover .icon {
	fill: #06c
}

.bottom-nav .it-ico {
	position: relative;
	font-size: 1.35rem;
	display: block;
	height: 32px
}

.bottom-nav .it-ico:before {
	margin-right: 0
}

.bottom-nav .badge-wrapper {
	position: relative
}

.bottom-nav .bottom-nav-alert,
.bottom-nav .bottom-nav-badge {
	display: block;
	position: absolute;
	background-color: #06c;
	border: 1px solid #fff
}

.bottom-nav .bottom-nav-badge {
	top: 0;
	right: 0;
	min-width: 18px;
	height: 18px;
	padding: 0 4px;
	color: #fff;
	font-size: .563rem;
	border-radius: 50%;
	font-style: normal
}

.bottom-nav .bottom-nav-alert {
	top: 4px;
	right: 8px;
	min-width: 10px;
	height: 10px;
	font-size: .563rem;
	border-radius: 50%
}

.bottom-nav .bottom-nav-label {
	margin-top: 6px;
	font-size: .688rem;
	line-height: 1;
	display: block;
	font-weight: 600
}

.navbar.it-navscroll-wrapper {
	background: #fff
}

.navbar.it-navscroll-wrapper .it-back-button {
	padding: 16px 24px;
	font-weight: 600;
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 10;
	display: none
}

.navbar.it-navscroll-wrapper .it-back-button i {
	-webkit-transform: scale(.8);
	transform: scale(.8);
	-webkit-transform-origin: center;
	transform-origin: center;
	display: inline-block;
	margin-right: 8px
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
	font-size: 1em;
	font-weight: 600;
	line-height: 1em;
	padding-top: .8em;
	padding-bottom: .8em
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
	border-left: 2px solid #06c
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
	color: #06c;
	text-decoration: none
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
	padding-top: 0;
	padding-bottom: 0
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
	font-weight: 400
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
	font-weight: 400;
	border-left: none
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
	text-decoration: underline;
	color: #17324d
}

.navbar.it-navscroll-wrapper h3 {
	font-weight: 600;
	margin-bottom: .8rem;
	font-size: .833em;
	margin-top: 5px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 24px;
	padding-right: 24px
}

.navbar.it-navscroll-wrapper .custom-navbar-toggler {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #06c;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 600;
	cursor: pointer
}

.navbar.it-navscroll-wrapper .custom-navbar-toggler span.it-list {
	color: #06c;
	margin-right: 8px
}

.navbar.it-navscroll-wrapper .menu-wrapper {
	padding: 80px 0 24px;
	right: 0
}

.navbar.it-bottom-navscroll {
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: auto
}

.navbar.it-top-navscroll {
	-webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, .05);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: auto
}

@media (min-width:992px) {
	.navbar.it-navscroll-wrapper {
		background: #fff
	}
	.navbar.it-navscroll-wrapper .it-back-button {
		display: none!important
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
		font-size: 1em;
		font-weight: 600;
		line-height: 1em;
		padding-top: .8em;
		padding-bottom: .8em
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
		border-left: 2px solid #06c
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
		color: #06c;
		text-decoration: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li {
		padding-top: 0;
		padding-bottom: 0
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a {
		font-weight: 400
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active {
		font-weight: 400;
		border-left: none
	}
	.navbar.it-navscroll-wrapper .link-list-wrapper ul li ul li a.active span {
		text-decoration: underline;
		color: #17324d
	}
	.navbar.it-navscroll-wrapper h3 {
		font-weight: 600;
		margin-bottom: .8rem;
		font-size: .833em;
		margin-top: 5px;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-left: 24px;
		padding-right: 24px
	}
	.navbar.it-navscroll-wrapper .custom-navbar-toggler {
		display: none
	}
	.navbar.it-navscroll-wrapper .menu-wrapper {
		padding: 16px 0 24px;
		background: #fff
	}
	.navbar.it-bottom-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-bottom-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-top-navscroll {
		-webkit-box-shadow: none;
		box-shadow: none;
		position: inherit
	}
	.navbar.it-top-navscroll.affix-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		left: inherit;
		right: inherit
	}
	.navbar.it-left-side {
		border-left: 1px solid #d9dadb
	}
	.navbar.it-right-side {
		border-right: 1px solid #d9dadb
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-desk .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-desk.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

@media (max-width:992px) {
	.navbar.it-navscroll-wrapper.theme-dark-mobile {
		background: #435a70
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .custom-navbar-toggler span.it-list {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .it-back-button,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper {
		background: #435a70;
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a,
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a span {
		color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile .navbar-collapsable .menu-wrapper .link-list-wrapper ul li a.active {
		border-left-color: #fff
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-left-side {
		border-left-color: rgba(229, 229, 229, .3)
	}
	.navbar.it-navscroll-wrapper.theme-dark-mobile.it-right-side {
		border-right-color: rgba(229, 229, 229, .3)
	}
}

.btn-full {
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	line-height: 1.555;
	border-radius: 0;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	width: inherit;
	margin-top: -7.5px;
	margin-bottom: -7.5px;
	margin-right: -7.5px;
	padding-left: 8px;
	padding-right: 8px
}

.btn-full:hover {
	text-decoration: none!important
}

@media (min-width:576px) {
	.btn-full {
		padding: 16px
	}
}

@media (min-width:992px) {
	.btn-full {
		padding: 12px 24px!important;
		margin: 0;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}
}