.loading-bar-wrapper {
    background-color: aliceblue;
    height: 5px;
    width: 100%;
    position: fixed;
    top: 0;
  }
  
  .loading-bar {
    background-color: #61dafb;
    width: 75%;
    height: 100%;
    position: relative;
    animation: loading-bar 1000ms linear infinite;
  }
  
  @keyframes loading-bar {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(125%);
    }
  }