code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.separator {
  border-left: 0.1em solid #fff;
  vertical-align: middle;
  margin-right: 0.3em;
  margin-left: 0.1em;
  font-size: 1.2em;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html,
body {
  min-height: 90%;
  padding: 0;
  margin: 0;
  font-family: "Titillium Web", HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}

#content {
  min-height: 80vh;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table.table-responsive-sm {
  padding: 0;
}

.svg-inline--fa {
  cursor: pointer;
}

hr.readMarker {
  position: relative;
  top: -1px;
  z-index: 1;
  will-change: width;
  opacity: 1;
  border: none;
  border-top: 1px solid #0066cc;
  border-bottom: 1px solid #0066cc;
  overflow: visible;
  text-align: center;
  height: 5px;
}

hr.readMarker:after {
  background: #fff;
    content: 'Ultimo evento letto';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.form-label{
  margin-top: 0.5rem;
}

.bg-orange {
  opacity: 1;
  background-color: #ff8f00 !important;
}

.error-state-select{
  padding: 1px;
  background-color: #dc3545;
  border-radius: .3rem;
}

/* scrollbar classes */

/* ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

/* scrollbar classes */


.border-custom{
  border-color: #888888 !important;
  border-width: 1.5px !important;
}
/* PER SELEZIONARE ANCHE GLI INPUT DI REACT-SELECT */
.border-custom div{
  border-color: #888888 !important;
  border-width: 1.5px !important;
}


/* FIX PAGINAZIONE */

@media (max-width: 992px) {
  .react-bootstrap-table-pagination div {
    display: block !important;
  }
}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list  {
  display: flex;
  justify-content: end;
  align-items: center;
}
.react-bootstrap-table-pagination div  {
  display: flex;
  justify-content: start;
  align-items: center;
}